.header.sticky-top {
	min-height: 63px;
}

.navbar {
	font-weight: bold;
}

.navbar > .container {
	padding: 0;
}

a.navbar-brand {
	white-space: normal;
	text-align: center;
	word-break: break-all;
}
