.fade-in-section {
	opacity: 0;
	transform: translateY(0vh);
	visibility: hidden;
	transition: opacity 1.25s ease-out, transform 0.8s ease-out;
	will-change: opacity, visibility;
	padding-bottom: 1rem;
}

.fade-in-section.is-visible {
	opacity: 1;
	transform: none;
	visibility: visible;
}
