.scroller {
	width: 3px;
	height: 10px;
	border-radius: 25%;
	background-color: #fff;
	animation-name: scroll;
	animation-duration: 2.2s;
	animation-timing-function: cubic-bezier(.15,.41,.69,.94);
	animation-iteration-count: infinite;
}

.home-start-center {
	max-width: 910px;
	margin: auto auto;
}

@keyframes scroll {
	0% {
		opacity: 0;
	}

	40% {
		transform: translateY(0);
		opacity: 1;
	}

	100% {
		transform: translateY(15px);
		opacity: 0;
	}
}
