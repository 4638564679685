:root {
	--fadeImageTime: 1.75s;
}

.container-image-spinner {
	background-color: grey;
	opacity: 0.5;
	z-index: 200;
}

.next-slide-timer {
	height: 6px;
	z-index: 101;
	background-color: #778899;
	border-bottom-right-radius: 0 !important;
}

.next-slide-radiocontainer {
	height: 21px;
	z-index: 101;
}

.fade-in-image {
	animation: fadeInImage var(--fadeImageTime) forwards;
}

.fade-out-image {
	animation: fadeOutImage var(--fadeImageTime) forwards;
}

.gallery_highlight {
	box-shadow: rgba(226, 226, 239, 0.25) 0px 0px 45px 0px, rgba(0, 0, 0, 0.25) 0px 4px 16px 0px;
	border-bottom-width: 0.25px;
	border-color: rgba(255, 255, 255, 0.5);
	border-left-width: 0.25px;
	border-right-width: 0.25px;
	border-style: solid;
	border-top-width: 0.25px;
}

@keyframes fadeInImage {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

@keyframes fadeOutImage {
	0% {
		opacity: 1;
	}

	100% {
		opacity: 0;
	}
}