.level_circle {
	display: inline-block;
	width: 13px;
	height: 13px;
	vertical-align: middle;
	margin-right: 8px;
	border-radius: 8px;
	border-color: white;
	border-style: solid;
	border-width: 1px;
}

.level_circle.checked {
	background-color: var(--main-text-color);
}