@import 'main_icon.css';
@import 'main_root.css';

html,
body {
	height: 100%;
	width: 100%;
	background-color: var(--main-background-color);
}

#root {
	min-height: 100%;
}

.main_background_color {
	background-color: var(--main-background-color) !important;
}

.main_color {
	color: var(--main-color) !important;
}

.rev_main_background_color {
	font-size: 15px;
	color: var(--main-background-color);
}

.rev_main_color {
	background-color: var(--main-color);
}

* {
	color: var(--main-color);
	font-size: 14px;
	z-index: 100;
	position: relative;
	font-family: Arial !important;
}

.container {
	max-width: 1224px;
}

#main_container {
	padding-top: 55px;
	padding-bottom: 110px;
}

.header,
.header_big,
.header_small,
.header_smallest {
	background-image: linear-gradient(97deg, rgb(255, 255, 255) 0%, rgb(127, 182, 213) 100%);
	background-clip: text;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	font-weight: bold;
	display: inline-block;
}

.header {
	font-size: calc(50px * var(--main-window-size-ratio));
	line-height: calc(60px * var(--main-window-size-ratio));
}

.header_big {
	font-size: calc(80px * var(--main-window-size-ratio));
	line-height: calc(95px * var(--main-window-size-ratio));
}

.header_small {
	font-size: calc(35px * var(--main-window-size-ratio));
	line-height: calc(42px * var(--main-window-size-ratio));
}

.header_smallest {
	font-size: calc(28px * var(--main-window-size-ratio));
	line-height: calc(34px * var(--main-window-size-ratio));
}

.header_simple {
	background-image: none !important;
	background-clip: unset;
	-webkit-background-clip: unset;
	-webkit-text-fill-color: unset;
	color: var(--main-text-color);
}

.text {
	font-size: calc(24px * var(--main-window-size-ratio));
	color: var(--main-text-color);
	text-align: justify;
}

.image_text_description {
	font-size: calc(18px * var(--main-window-size-ratio));
}

footer .text {
	font-size: calc(15px * var(--main-window-size-ratio));
}

.fullpage_container {
	min-height: calc(100vh - 55px);
	display: flex;
	flex-direction: column;
}

.fullpage_container_full_height {
	min-height: calc(100vh);
}

@media (max-width: 1223px) and (min-width: 576px) {
	#main_container {
		padding-bottom: 100px;
	}
}

@media (max-width: 575px) {
	#main_container {
		padding-bottom: 92px;
	}
}