:root {
	--main-background-color: #040615;
	--main-color: #ffffff;
	--main-text-color: #ced6de;

	--main-window-size-ratio: 1;
}

@media (max-width: 1223px) and (min-width: 576px) {
	:root {
		--main-window-size-ratio: 0.8;
	}
}

@media (max-width: 575px) {
	:root {
		--main-window-size-ratio: 0.6;
	}
}