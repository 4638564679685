@import '../../style/main_root.css';

.header_container_expertise {
	max-width: 736px;
}

.services_container_expertise {
	min-width: calc(300px * var(--main-window-size-ratio));
}

.text.text_small_expertise {
	font-size: calc(20px * var(--main-window-size-ratio));
}