#top_transition {
	position: absolute;
	top: 0;
	z-index: 1;
	pointer-events: none;
	background: radial-gradient(80% 100% at 50% 0%, rgba(38, 140, 245, 0.25) 0%, rgba(38, 140, 245, 0) 100%);
	height: 45vh;
	width: 100%;
}

@media (max-width: 575px) {
	.navbar-collapse li {
		border: 1px solid rgba(255,255,255, 0.15);
		border-bottom: 0;
		border-left: 0;
		border-right: 0;
	}

	.navbar-collapse .rev_main_background_color {
		margin-top: 14px;
		margin-bottom: 14px;
	}
}
