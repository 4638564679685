.icon {
	width: calc(25px * var(--main-window-size-ratio));
	height: calc(25px * var(--main-window-size-ratio));
	display: inline-block;
	background-repeat: no-repeat !important;
	background-size: contain !important;
}

.s-icon {
	width: 16px;
	height: 16px;
}

.icon_hamburger_64_64 {
	background: url('./icons/hamburger_64_64.png');
	width: 26px;
	height: 26px;
}

.icon_linkedin {
	background-image: url("data:image/svg+xml;utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20viewBox%3D%220%200%2020%2020%22%3E%3Cg%20id%3D%22ss3954193324_1%22%3E%3Cpath%20d%3D%22M%200%2020%20L%200%200%20L%2020%200%20L%2020%2020%20Z%22%20fill%3D%22transparent%22%3E%3C%2Fpath%3E%3Cpath%20d%3D%22M%2019.167%200%20L%200.833%200%20C%200.333%200%200%200.333%200%200.833%20L%200%2019.167%20C%200%2019.667%200.333%2020%200.833%2020%20L%2019.167%2020%20C%2019.667%2020%2020%2019.667%2020%2019.167%20L%2020%200.833%20C%2020%200.333%2019.667%200%2019.167%200%20Z%20M%205.917%2017.083%20L%203%2017.083%20L%203%207.5%20L%206%207.5%20L%206%2017.083%20Z%20M%204.417%206.167%20C%203.5%206.167%202.667%205.417%202.667%204.417%20C%202.667%203.5%203.417%202.667%204.417%202.667%20C%205.333%202.667%206.167%203.417%206.167%204.417%20C%206.167%205.417%205.417%206.167%204.417%206.167%20Z%20M%2017.083%2017.083%20L%2014.083%2017.083%20L%2014.083%2012.417%20C%2014.083%2011.333%2014.083%209.917%2012.583%209.917%20C%2011%209.917%2010.833%2011.083%2010.833%2012.333%20L%2010.833%2017.083%20L%207.833%2017.083%20L%207.833%207.5%20L%2010.667%207.5%20L%2010.667%208.833%20C%2011.083%208.083%2012%207.333%2013.5%207.333%20C%2016.5%207.333%2017.083%209.333%2017.083%2011.917%20Z%22%20fill%3D%22rgb(122%2C133%2C145)%22%3E%3C%2Fpath%3E%3C%2Fg%3E%3C%2Fsvg%3E");
}

.icon_email_50_50 {
	background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAACyUlEQVR4nO1YXYhMYRj+xm/+kr8SIorkYovcSElKuaL8lAuUm72TxNp5v1FH5n1nZ63Zfd/ZSQ13ZNPkDiUupBQlbUn5CzdC2EL+f/bomzPjrLWNtXPOntnpe+ptLmbON+/z/j7fUcrCwsLCIgpoErceTNUPEeQfdUEknpINQNITtSNDNuReIMZinzSneT4Q3xp5JOQ9UHZzkUQ81bHKfDpOfiIQd0XuHA3OgPjOoSO82OPQOUMByXeN0vx7iqE0auRvNU6ia39b26RiNbXIciB+4k8t5FP7MpkJ5stEsmONRnlZewTkj6DHU7ITiD/+NX6B5HYimVtgfnQwLfM0ys2ondd+oF8lktl1xrc9IuOBWCruESB53e+BkzVA4npTa+ccr/Qzc4H4xqAW4kB9AyRfIyqnvOM44/yS5xf/v9lRzphpZg4Byq4GkufDR4A/x1O8uxhJ140B8d5KQ+ifEgVIuhPJ7EKPjMwCkqvhl5I8AuIGby3kJgNJIRithfwGiNd7BztjgDgdYiYuxFuOTyvthyVAcjdY0Wg0mekb14152eEdGuVTwFIj7TjOqNKi3qiR34amfoHkbN9lpFGeVp8F6TGaTymlthUKo4HksCEWvoxHvgeUXeqV2rGZQHylChLd8Zb2ReWzNMrlYb2PAPE7jbLJj+IQ+gbldHkqamxfUU12VZB1DcTbNcqHQTz3xYxTf0917Kq231RVRPzInm9O56eWyDRolMcVSulZWXEb5aBRTtTWVRf5AZAs82o9Mx1ILg1A4tqBo7nZYWg5FRiR/hcd142ZcQ0kP0sk8o35/NiS1FgbtLpWgRLxMtNrxme5bzTyFiDZ2occhPGeQAVOxM/OxfKGNmhqbZ0CJOdG5OsgIHmosXOlMSC5H+Z/qTAPH05TUTtgiZDNiERePtr2CEUfaW0zQtFHV9uMUB1kxMLCwsJCRYBfKXk8FUltKTsAAAAASUVORK5CYII=");
}